<template>
    <div>
        <v-container>
            <v-row>
                <div class="col-md-12 col-sm-12">
                    <v-card denese>
                        <v-row style="margin:auto" >
                            <v-col cols="5" lg="5" md="5" sm="12">
                                <s-select-definition 
                                    :def=1450 
                                    label="Almacenes de congelado" 
                                    v-model="CdtWarehouses"
                                    @input="changeChamber()"
                                >
                                </s-select-definition>  
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-row>
            <v-row>
                <div class="col-md-12 col-sm-12">
                    <v-card denese>
                        <v-row style="margin: auto;">
                            <v-col 
                                cols="6"
                                lg="3"
                                md="4"
                                v-for="item in itemsTunnel"
                                :key="item.PtnID">
                                <v-card dense class="mx-auto">
                                    <v-img lazy-src="https://img.interempresas.net/fotos/2852264.jpeg"
										
										src="https://img.interempresas.net/fotos/2852264.jpeg">
                                        <v-btn style=" margin: auto; display: flex; justify-content: space-evenly; "
                                            color="warning"
                                            fab
                                            width="100"
                                            height="100"
                                            @click="openModal(item)"
                                            class="mb-8 mt-8">
                                            <span class="white--text text-h4">
                                                <b>
                                                    <h1 style="font-size:20px">
                                                    {{ item.CdtDescripcion }}</h1>
                                                    <!-- {{ item.PtnWeigthNet + ' TM' }}</h1> -->
                                                </b>
                                            </span>
                                        </v-btn>
                                    </v-img>
                                    <v-card style=" margin: auto; display: flex; justify-content: space-evenly; "
                                        class="white--text" 
                                        :color="item.inColor" outlined >
                                        <h3> {{ item.PtnDescripcion }}</h3>
                                    </v-card>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-row>
        </v-container>
        <v-dialog 
            v-model="dialogDetailAdmin"
            fullscreen
            >
            <v-card class="pl-3 pt-3">
                <v-row>
                    <v-col>
                        <s-toolbar
                            label=""
                            close
                            @close="closeAdmin()"
                        />
                    </v-col>
                </v-row>
                <v-row v-for="side in tunnel.CdtNumberSides">
                    <v-col cols="12">Lado {{ side }}</v-col>
                    <v-col  cols="1" v-for="column in tunnel.CdtNumberColumn">
                        <v-card 
                            color="#EEEEEE"
                            class="pl-1 pr-1 p-0">
                        <v-row
                            class="p-0">
                            <v-col cols="12" v-for="level in tunnel.MaxLevel">
                                    <div class="d-flex justify-space-between">
                                        <v-sheet 
                                        width="30%"
                                            v-for="lane in tunnel.CdtLane"
                                            >
                                            <v-card 
                                                width="*"
                                                height="50"
                                                @click="moved(side, column, level, lane)"
                                                :color="tunnelInfo.Levels?.filter((x) => x.CdlLevel == level)[0]?.CdlColor">   
                                                    <v-card 
                                                        color="grey"
                                                        height="40"
                                                        v-for="pallet in tunnelInfo.Pallets?.filter((x) => x.NumberSide == side 
                                                                && x.NumberColumn == column
                                                                && x.CdlLevel == level
                                                                && x.Lane == lane
                                                                )">
                                                        {{ pallet.CdlDscription }}   
                                                    </v-card>
                                                
                                                </v-card> 
                                        </v-sheet> 
                                    </div> 
                                </v-col> 
                        </v-row>
                        </v-card>
                    </v-col> 
                </v-row> 
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="dialogDetail"
            >
           
            <v-card> 
                <s-toolbar
                    color="grey"
                    close
                    @close="dialogDetail = false;"
                    label="Parihuela en el tunel"
                    dark
                />
                <v-row class="ml-3 ">
                    <v-col>
                        <v-radio-group
                            inline
                            v-model="filter.NumberSide"
                            row
                            class=""
                            hide-details
                            >
                            <template v-slot:label>
                                <div>Lados <strong></strong></div>
                            </template>
                            <v-radio v-for="side in this.tunnel.CdtNumberSides" :label="side.toString()" :value="side"></v-radio>
                            
                        </v-radio-group> 
                     
                     
                    
                        <v-radio-group
                            inline
                            v-model="filter.CdlLevel"
                            row
                            hide-details>
                            <template v-slot:label>
                                <div>Nivel <strong></strong></div>
                            </template>
                            <v-radio v-for="level in tunnelInfo.Levels" :label="level.Dscription" :value="level.CdlLevel"></v-radio> 
                        </v-radio-group>   
                        
                        <v-radio-group
                            inline
                            v-model="filter.NumberColumn"
                            row
                            hide-details>
                            <template v-slot:label>
                                <div>Columna <strong></strong></div>
                            </template>
                            <v-radio v-for="column in this.tunnel.CdtNumberColumn" :label="column.toString()" :value="column"></v-radio> 
                        </v-radio-group> 
                        
                        <v-radio-group
                            inline
                            v-model="filter.Lane"
                            row
                            hide-details>
                            <template v-slot:label>
                                <div>Carril <strong></strong></div>
                            </template>
                            <v-radio v-for="lane in this.tunnel.CdtLane" :label="lane.toString()" :value="lane"></v-radio>
                            
                        </v-radio-group>    
                    </v-col>
                    <v-col cols="12"  >
                        <v-checkbox 
                            v-model="allView"
                            label="Todos"
                            hide-details></v-checkbox> 
                    </v-col>
                    <v-col cols="12"  >
                        <v-btn
                            color="warning"
                            @click="openModalAdmin()">
                            Mover
                        </v-btn>
                    </v-col>
                </v-row>
               
                <v-row>
                    <v-col>
                        <v-data-table
                            :items="tunnelInfo.Pallets"
                            :headers="headerDetail"
                            v-model="palletSel"
                            dense
                            show-select
                            item-key="CipID"
                        >
                           
                            <template v-slot:item.CipDateBegin="{ item }">
                                {{ $fun.formatDateTimeView(item.CipDateBegin) }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    
    import _sPackingTunnel from '@/services/FrozenProduction/FrzChamberDispatchTunnels.js';
    import _sFrzCameraIncomePallet from "@/services/FrozenProduction/FrzCameraIncomePallet.js";

    export default{
        components: {},
        data(){
            return {
                itemsTunnel:[],
                dialogDetail: false,
                dialogDetailAdmin: false,
                tunnelInfo: {
                    Pallets: []
                },
                tunnel: {},
                detail: [],
                headerDetail: [
                    { text: "Codigo", value: "CipID" },
                    { text: "Lado", value: "NumberSide" },
                    { text: "Nivel", value: "CdlDscription" },
                    { text: "Columna", value: "NumberColumn" },
                    { text: "Carril", value: "Lane" },
                    { text: "Fecha de Ingreso", value: "CipDateBegin" },
                    { text: "Cultivo", value: "TypeCultive" },
                    { text: "Tipo Corte", value: "TypeCut" },
                    { text: "Tipo presentacion", value: "TypePresentation" },
                ],
                filter: {
                    //  Lane: null,
                    //  NumberColumn: null,
                    //  CdlLevel: null,
                    //  NumberSide: null,
                },
                allView:false,
                palletSel: [],
                CdtWarehouses: 0,
            }
        },
        watch: {
            filter(){
                console.log("filter", this.filter)
                let obj = structuredClone(this.filter);
                obj.CdtID = this.tunnel.CdtID;
                this.getDetail(obj);
            },
            allView(){
                this.filter = {}
            },
        },
        methods: {
            closeAdmin(){
                 
                this.dialogDetailAdmin = false;
            },
            changeChamber() {
                console.log('almacen',this.CdtWarehouses)
                _sPackingTunnel.list({CdtWarehouses : this.CdtWarehouses}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        r.data.forEach(element => {
                            element.ParihuelaUsagePorcentaje = (100 * element.inCarUsage) /  parseInt(element.PtnCapacity)
                            
                            if(element.ParihuelaUsagePorcentaje >= 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 35 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 35){
								element.inColor = 'success'
							}
                        });
                        this.itemsTunnel = r.data;
                    }
                });
            },
            openModal(item){
                this.palletSel = [];
                this.tunnel = item;
                console.log("this.item", item);
                 
                let obj = structuredClone(this.filter);
                obj.CdtID = item.CdtID;
                this.getDetail(obj);

               
            },
            moved(side, column, level, lane){
                

                let tunsel = this.tunnelInfo.Pallets?.filter((x) => x.NumberSide == side 
                                                                && x.NumberColumn == column
                                                                && x.CdlLevel == level
                                                                && x.Lane == lane
                                                                );

                if (tunsel.length > 0) {
                    this.$fun.alert("Esa posicion ya esta ocupada",  "warning");
                    return;
                }

                let lvel = this.tunnelInfo.Levels?.filter((x) => x.CdlLevel == level)[0];

                let item = this.palletSel[0]; 
				item.UsrUpdateID = this.$fun.getUserID(); 
                item.Lane = lane;
                item.NumberColumn = column;
                item.NumberSide = side;
                item.CdlID = lvel.CdlID;

                console.log("item save", item)
                 
                this.$fun.alert("¿Esta seguro de mover el pallet?", "question")
                    .then((a) => {

                        if (a.value) {
                         
                            _sPackingTunnel.moved(item, this.$fun.getUserID())
                            .then( r => {
                                if(r.status == 200)
                                {
                                    // this.$refs.gridPackingAssingBingToLine.refresh();
                                    this.$fun.alert("Pallet ingresado correctamente", "success");
                                    this.palletSel = [];
                                    this.tunnelInfo.Pallets = [];
                                    let obj = structuredClone(this.filter);
                                    obj.CdtID = this.tunnel.CdtID;
                                    this.getDetail(obj);
                                }
                            });   
                        }
                    });
               
            },
            getDetail(filter){

                _sPackingTunnel.detail(filter, this.$fun.getUserID())
                    .then((r) => {

                        if (r.status == 200) {
                            this.tunnelInfo = r.data;
                            console.log("this.detail", this.tunnelInfo); 
                            
                            this.dialogDetail = true;
                        }

                    })
                
            },
            openModalAdmin(){
                let item = structuredClone(this.tunnel); 
                console.log("this.item", item);
                 
                _sPackingTunnel.detail(item, this.$fun.getUserID())
                    .then((r) => {

                        if (r.status == 200) {
                            this.tunnelInfo = r.data;
                            console.log("this.tunnelInfo", this.tunnelInfo); 
                            
                            this.dialogDetailAdmin = true;
                        }

                    })
                
               
            }
        },
        mounted()
        {
            // this.initialize();
        }
    }
</script>